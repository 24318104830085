import { Injectable }     from '@angular/core';
import { CanActivate }    from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class CollaboratorAuthGuard implements CanActivate {
    canActivate() {
        // Your redirect logic/condition. I use this.
        const isCollaborator = window.localStorage.getItem('collaborator');
        if (!isCollaborator || isCollaborator !== 'true') {
            this.router.navigate(['/login']);
            // return false;
        }
        const selected_view = window.localStorage.getItem('selected_view');
        if (!selected_view || selected_view !== 'collaborator') {
            this.router.navigate(['/login']);
            // return false;
        }
        // console.log('AuthGuard#canActivate called');
        return true;
    }
    // Constructor
    constructor(private router: Router) { }
}
