import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { AdminAuthGuard } from './services/roles-guards/admin-auth-guard.service';
import { ResearcherAuthGuard } from './services/roles-guards/researcher-auth-guard.service';
import { CollaboratorAuthGuard } from './services/roles-guards/collaborator-auth-guard.service';
import { InstitutionalUserAuthGuard } from './services/roles-guards/institutional-user-auth-guard.service';

const routes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'configuration', loadChildren: './configuration/configuration.module#ConfigurationModule'},
    { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'password-recovery', loadChildren: './password-recovery/password-recovery.module#PasswordRecoveryModule' },
    { path: 'pass-rec/:token', loadChildren: './pass-rec/pass-rec.module#PassRecModule' },
    { path: 'admin', loadChildren: './layouts/admin/layout.module#LayoutAdminModule', canActivate: [AdminAuthGuard]},
    { path: 'collaborator', loadChildren: './layouts/collaborators/layout.module#LayoutCollaboratorModule', canActivate: [CollaboratorAuthGuard]},
    { path: 'institutional', loadChildren: './layouts/institutional/layout.module#LayoutInstitutionalUserModule', canActivate: [InstitutionalUserAuthGuard]},
    { path: 'researcher', loadChildren: './layouts/researchers/layout.module#LayoutResearcherModule', canActivate: [ResearcherAuthGuard]},


];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    providers: [AdminAuthGuard, ResearcherAuthGuard, CollaboratorAuthGuard, InstitutionalUserAuthGuard],
    exports: [RouterModule]
})
export class AppRoutingModule {}
