import { Injectable }     from '@angular/core';
import { CanActivate }    from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AdminAuthGuard implements CanActivate {
    canActivate() {
        // Your redirect logic/condition. I use this.
        const isAdmin = window.localStorage.getItem('admin');
        if (!isAdmin || isAdmin !== '0') {
            this.router.navigate(['/login']);
        }
        const selected_view = window.localStorage.getItem('selected_view');
        if (!selected_view || selected_view !== 'admin') {
            this.router.navigate(['/login']);
        }
        // console.log('AuthGuard#canActivate called');
        return true;
    }
    // Constructor
    constructor(private router: Router) { }
}
