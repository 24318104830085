import { Injectable }     from '@angular/core';
import { CanActivate }    from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class InstitutionalUserAuthGuard implements CanActivate {
    canActivate() {
        // Your redirect logic/condition. I use this.
        const isInstitutionalUser = window.localStorage.getItem('institutional_user');
        if (!isInstitutionalUser || isInstitutionalUser !== 'true' || !this.haveInstitutions()) {
            this.router.navigate(['/login']);
        }
        const selected_view = window.localStorage.getItem('selected_view');
        if (!selected_view || selected_view !== 'institutional_user') {
            this.router.navigate(['/login']);
        }
        // console.log('AuthGuard#canActivate called');
        return true;
    }
    // Constructor
    constructor(private router: Router) { }


    haveInstitutions() {
        const institutions = window.localStorage.getItem('entidad');
        if (institutions === null || institutions === '0' || institutions.length === 0 || institutions === '') {
            return false;
        } else {
            return true;
        }
    }
}
